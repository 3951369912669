// app.js

import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { Token, TradeType, Route, Trade, Percent } from '@uniswap/sdk-core';
import { AlphaRouter } from '@uniswap/smart-order-router';

const App = () => {
  const [account, setAccount] = useState(null);
  const [amount, setAmount] = useState('1');
  const [maxGasFee, setMaxGasFee] = useState('300');
  const [slippage, setSlippage] = useState('0.5');
  const [maxTime, setMaxTime] = useState('60');
  const [swapData, setSwapData] = useState(null);

  const router = new AlphaRouter();

  useEffect(() => {
    if (!window.ethereum) {
      alert('Please install MetaMask!');
    }
  }, []);

  const connectWallet = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setAccount(accounts[0]);
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    }
  };

  const handleSwap = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const tokenAddress = '0xEeFD5b464F7BDa0cde5a7Db822A3ef7c426e7a05';
      const token = new Token(1, tokenAddress, 18, 'TOKEN', 'Custom Token');
      const weth = new Token(1, ethers.constants.AddressZero, 18, 'WETH', 'Wrapped Ether');

      const route = await router.route(
        token,
        weth,
        TradeType.EXACT_INPUT,
        amount,
        {
          recipient: account,
          slippageTolerance: new Percent(slippage, '100'),
          deadline: Math.floor(Date.now() / 1000) + parseInt(maxTime),
        },
      );

      setSwapData(route);

      const tx = {
        from: account,
        to: route.routerAddress,
        data: route.callData,
        value: route.inputAmount.raw.toString(),
        gasPrice: web3.utils.toWei(maxGasFee, 'gwei'),
      };

      const transactionHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [tx],
      });

      alert(`Transaction sent! Hash: ${transactionHash}`);
    } catch (error) {
      console.error('Error during swap:', error);
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Uniswap v3 Swap</h1>
      {!account && <button onClick={connectWallet}>Mit MetaMask verbinden</button>}
      {account && (
        <div>
          <p>Verbunden mit: {account}</p>

          <div>
            <label>
              Betrag (Amount):
              <input
                type="text"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </label>
          </div>

          <div>
            <label>
              Maximale Gasgebühr (Max Gas Fee):
              <input
                type="text"
                value={maxGasFee}
                onChange={(e) => setMaxGasFee(e.target.value)}
              />
            </label>
          </div>

          <div>
            <label>
              Slippage (%):
              <input
                type="text"
                value={slippage}
                onChange={(e) => setSlippage(e.target.value)}
              />
            </label>
          </div>

          <div>
            <label>
              Maximale Zeit (Sekunden):
              <input
                type="text"
                value={maxTime}
                onChange={(e) => setMaxTime(e.target.value)}
              />
            </label>
          </div>

          <button onClick={handleSwap}>Senden</button>
        </div>
      )}
    </div>
  );
};

export default App;
